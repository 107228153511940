import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import './LocalSpreadsheet.css'; // Import the CSS file for styling

function LocalSpreadsheet() {
  const [sheetData, setSheetData] = useState([]);

  useEffect(() => {
    // Fetch the local spreadsheet file from the public folder
    const fetchSpreadsheet = async () => {
      const response = await fetch('100 Club - 2024-25.xlsx'); // Fetch the file from the public folder
      const arrayBuffer = await response.arrayBuffer();
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      // Filter the columns A to G (first 7 columns)
    const filteredData = jsonData.map(row => {
        const keys = Object.keys(row).slice(0, 3); // Get only the first 4 keys (columns A-D)
        const filteredRow = {};
        keys.forEach(key => {
                                filteredRow[key] = row[key]; // Add the A-G column data to the filtered row
                            });
        return filteredRow;
    });

      setSheetData(jsonData);
    };

    fetchSpreadsheet();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <table>
          <thead>
            <tr>
              {sheetData.length > 0 &&
                Object.keys(sheetData[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {sheetData.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((val, i) => (
                  <td key={i}>{val}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </header>
    </div>
  );
}

export default LocalSpreadsheet;
