import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./App.css";
import ClubDraw from "./ClubDraw";

function App() {
  return (
    <Router>
      <div className="App">
        {/* Navigation bar */}
        <nav className="navbar">
         
          <ul className="nav-links">
            {/* Home Link */}
            <li><Link to="/Home">Home</Link></li>

            {/* Monthly Club Draw Link */}
            <li><Link to="/ClubDraw">Monthly Club Draw</Link></li>
          </ul>

          <div className="logo">
            <img src="/trawden-celtic-logo.png" alt="Trawden Celtic FC Logo" className="club-logo" />
          </div>
        </nav>

        <Routes>
          {/* Home page */}
          <Route path="/Home" element={
            <header className="App-header">
              <h1>Website Under Construction</h1>
              <p>We're working hard to get everything ready!</p>
              <h2>For now - Please take a look at our Monthly Club Draw page</h2>
            </header>
          } />

          {/* Monthly Club Draw page */}
          <Route path="/ClubDraw" element={<ClubDraw />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
