import React from "react";
import LocalSpreadsheet from "./LocalSpreadsheet";
import './LocalSpreadsheet.css'; // Import the CSS file for styling


function ClubDraw() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Monthly Club Draw</h1>
        <h2>Previous Winners</h2>
        
        {/* Image Placeholder */}
        <div className="image-placeholder">
          <img src="/draw-winners.png" alt="Club Draw Placeholder" className="previous-draw-winners"/>
        </div>

        <br>
        </br>
        <br>
        </br>

        <h2>Members List</h2>
        <LocalSpreadsheet />

      </header>
    </div>
  );
}

export default ClubDraw;
